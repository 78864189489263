import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ErrorBoundary from './Modules/ErrorBoundary';
import { NotificationProvider } from "./Notifications/NotificationProvider";
import ImageCacheProvider from './Modules/ImageCacheProvider';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import './App.css';
import './ModeratorBoard.css';
import './Article.css';
import './Articles.css';
import './MusicPlayer.css';
import './Navbar.css';
import './NewUserNavBar.css';
import './NotificationDropdown.css';
import './CollegeDisplay.css';
import './DemonTutors.css';
import './Dropdown.css';
import './EditArticle.css';
import './RobloxGames.css';
import './RobloxLimitedSpecific.css';
import './RobloxShop.css';
import './Search.css';
import './Settings.css';
import './Voice.css';
import './LoggedOutDashboard.css';
import './Login.css';
import './MathSolver.css';
import './MiniPlayer.css';
import './ChatDashboard.css';
import './Home.css';
import './Profile.css';
import './Dashboard.css';
import './Friends.css';
import './Games.css';
import './RobloxHome.css';
import './Quiz.css';

const Home = lazy(() => import('./Home'));
const NotFound = lazy(() => import('./NotFound'));
const DemonTutors = lazy(() => import('./DemonTutors'));
const Navbar = lazy(() => import('./Navbar'));
const NewUserNavBar = lazy(() => import('./NewUserNavBar'));
const LoggedOutDashboard = lazy(() => import('./LoggedOutDashboard'));
const LoggedInDashboard = lazy(() => import('./LoggedInDashboard'));
const Login = lazy(() => import('./Login'));
const Snake = lazy(() => import('./Snake'));
const Voice = lazy(() => import('./Voice'));
const Profile = lazy(() => import('./Profile'));
const Article = lazy(() => import('./Article'));
const Settings = lazy(() => import('./Settings'));
const Search = lazy(() => import('./Search'));
const RobloxShop = lazy(() => import('./RobloxShop'));
const RobloxUGC = lazy(() => import('./RobloxUGC'));
const RobloxHome = lazy(() => import('./RobloxHome'));
const MathSolver = lazy(() => import('./MathSolver'));
const SearchRobloxLimiteds = lazy(() => import('./SearchRobloxLimiteds'));
const SearchRobloxUsers = lazy(() => import('./SearchRobloxUsers'));
const RobloxGamesSpecific = lazy(() => import('./RobloxGamesSpecific'));
const BillyVsPotatoHead = lazy(() => import('./BillyVsPotatoHead'));
const RobloxUserSpecific = lazy(() => import('./RobloxUserSpecific'));
const RobloxLimitedSpecific = lazy(() => import('./RobloxLimitedSpecific'));
const Redeem = lazy(() => import('./Redeem'));
const HiBye = lazy(() => import('./HiBye'));
const Games = lazy(() => import('./Games'));
const Malakeye = lazy(() => import('./Malakeye'));
const CollegeDisplay = lazy(() => import('./CollegeDisplay'));
const FFFollowing = lazy(() => import('./FriendsFollowersFollowing'));
const ChatDashboard = lazy(() => import('./ChatDashboard'));
const RobloxGames = lazy(() => import('./RobloxGames'));
const EditArticle = lazy(() => import('./EditArticle'));
const MusicPlayer = lazy(() => import('./MusicPlayer'));
const MiniPlayer = lazy(() => import('./MiniPlayer'));
const MiniVideoCall = lazy(() => import('./MiniVideoCall'));
const Quiz = lazy(() => import('./Quiz'));
const UpdateAcc = lazy(() => import('./UpdateAcc'));
const LoadingPage = lazy(() => import('./Loading'));
const ModeratorBoard = lazy(() => import('./ModeratorBoard'));
const Articles = lazy(() => import('./Articles'));
const Mvc = lazy(() => import('./Mvc'));
const Media = lazy(() => import('./Media'));
const Secure = lazy(() => import('./Secure'));
const DemoTutors = lazy(() => import('./DemoTutors'));
const GoKartDemo = lazy(() => import('./GoKartDemo'));
const EssayReviewer = lazy(() => import('./EssayReviewer'));
const Course = lazy(() => import('./Course'));
const Harvard = lazy(() => import('./Harvard'));
const UPenn = lazy(() => import('./UPenn'));
const CalTech = lazy(() => import('./CalTech'));
const Columbia = lazy(() => import('./Columbia'));
const Cornell = lazy(() => import('./Cornell'));
const Duke = lazy(() => import('./Duke'));
const GeorgiaTech = lazy(() => import('./GeorgiaTech'));
const Northwestern = lazy(() => import('./Northwestern'));
const Stanford = lazy(() => import('./Stanford'));
const Urbana = lazy(() => import('./Urbana'));
const Yale = lazy(() => import('./Yale'));
const Princeton = lazy(() => import('./Princeton'));
const Umich = lazy(() => import('./Umich'));
const Vanderbilt = lazy(() => import('./Vanderbilt'));

const Fire = lazy(() => import('./Fire'));
const DinoHarvardGame = lazy(() => import('./DinoHarvardGame'));
Modal.setAppElement('#root');

function App() {
  const [akrCookie, setAkrCookie] = useState(null);
  const [akrAuth, setAkrAuth] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoCall, setVideoCall] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [disableNavbar, setDisableNavbar] = useState(false);
  const [audioState, setAudioState] = useState({
    isPlaying: false,
    currentTrack: null,
    audioElement: null,
    currentTime: 0,
    duration: 0,
  });

  useEffect(() => {
    const { isPlaying, audioElement } = audioState;
    if (audioElement === null) return;
    if (isPlaying) {
      audioElement.play().catch(error => console.error("Audio play failed", error));
    } else {
      audioElement.pause();
    }
  }, [audioState]);

  const setMiniPlayer = (show, track) => {
    if (show) {
      setAudioState({
        ...audioState,
        isPlaying: true,
        currentTrack: track,
        audioElement: new Audio(track.url),
      });
    } else {
      setAudioState({
        ...audioState,
        isPlaying: false,
      });
    }
  };

  //const [extraNotifications, addExtraNotifications] = useState({});

  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    try {
      const savedAkrCookie = Cookies.get('akrCookie');
      const savedAkrAuth = Cookies.get('akrAuth');
      if (savedAkrCookie) setAkrCookie(savedAkrCookie);
      if (savedAkrAuth) setAkrAuth(JSON.parse(savedAkrAuth));

      if (savedAkrAuth && savedAkrCookie) {
        try {
          let parsedJSON = JSON.parse(savedAkrAuth)
          if (!loggedIn && savedAkrCookie !== null && parsedJSON !== null) {
            setLoggedIn(true);
            console.log(`Logged in as ${parsedJSON?.fullName} (${parsedJSON?.userId})`)
          }
        } catch (error) {
          console.log('Error with saved user:', error);
        }
      }
      setIsLoaded(true)
    } catch (error) {
      console.error('Error with saved user:', error);
    }
  }, []);


  useEffect(() => {
    document.documentElement.setAttribute("theme", theme);
  }, [theme]);


  useEffect(() => {
    if (!loggedIn && akrCookie !== null && akrAuth !== null) {
      setLoggedIn(true);
      console.log(`Logged in as ${akrAuth?.fullName} (${akrAuth?.userId})`)
    }
  }, [akrCookie, akrAuth, loggedIn]);

  const setCookie = async (type, cookie) => {
    try {
      if (type === "akrCookie") {
        Cookies.set('akrCookie', cookie, { expires: 7 });
        setAkrCookie(cookie)
      }
      if (type === "akrAuth") {
        Cookies.set('akrAuth', JSON.stringify(cookie), { expires: 7 });
        setAkrAuth(cookie)
      }
    } catch (error) {
      console.error('Error:', error);
      return;
    }
  };

  const logOutUser = async () => {
    try {
      console.log(`Logging out of ${akrAuth?.fullName} (${akrAuth?.userId})`)
      setAkrCookie(null);
      setAkrAuth(null)
      Cookies.remove('akrCookie');
      Cookies.remove('akrAuth');
      setLoggedIn(false);
      return 'OK';
    } catch (error) {
      console.error('Error:', error);
      return;
    }
  };

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.overflowX = 'hidden';

    return () => {
      document.body.style.overflowX = '';
      document.documentElement.style.overflowX = '';
    };
  }, []);


  return (
    <Suspense fallback={<LoadingPage />}>
      <ErrorBoundary>
        <ImageCacheProvider>
          <NotificationProvider>
            <Router>
              <div>
                {audioState.isPlaying && <MiniPlayer audioState={audioState} setAudioState={setAudioState} setVideoCall={setVideoCall} />}
                {videoCall && <MiniVideoCall videoCall={videoCall} setVideoCall={setVideoCall} />}
                {newUser &&
                  <NewUserNavBar
                    akrCookie={akrCookie}
                    setAkrCookie={setAkrCookie}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    akrAuth={akrAuth}
                    logOutUser={logOutUser}
                  />
                }
                {!disableNavbar &&
                  <Navbar
                    akrCookie={akrCookie}
                    setAkrCookie={setAkrCookie}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    akrAuth={akrAuth}
                    logOutUser={logOutUser}
                  />
                }
                <Routes>
                  <Route path="/" element={isLoaded ? (loggedIn ? <LoggedInDashboard akrAuth={akrAuth} /> : <Login setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />) : <LoadingPage />} />
                  <Route
                    path="/login"
                    element={<Login setCookie={setCookie} setLoggedIn={setLoggedIn} loggedIn={loggedIn} />}
                  />
                  <Route path="/music" element={<MusicPlayer setMiniPlayer={setMiniPlayer} audioState={audioState} setAudioState={setAudioState} setVideoCall={setVideoCall} />} />
                  <Route
                    path="/testHP"
                    element={<LoggedOutDashboard setCookie={setCookie} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/profile"
                    element={
                      loggedIn ? (
                        <Navigate to={`/profile${("/" + ((akrAuth) ? akrAuth?.userId : "1")) || ''}`} replace />
                      ) : (
                        <Login setCookie={setCookie} setLoggedIn={setLoggedIn} loggedIn={loggedIn} />
                      )
                    }
                  />
                  <Route
                    path="/profile/:userId"
                    element={<Profile loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/testLoading"
                    element={<LoadingPage />}
                  />
                  <Route
                    path="/Mvc"
                    element={<Mvc />}
                  />
                  <Route path="/azarathMetrionZinthos/:universityId" element={<CollegeDisplay />} />
                  <Route path="/commonapp/:universityId"
                    element={<CollegeDisplay />}
                  />
                  <Route
                    path="/quiz"
                    element={<Quiz setCookie={setCookie} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/moderatorboard123"
                    element={<ModeratorBoard loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/gokartdemo"
                    element={<GoKartDemo akrAuth={akrAuth} />}
                  />
                  <Route
                    path="/signup"
                    element={<Login setAkrCookie={setAkrCookie} setLoggedIn={setLoggedIn} />}
                  />
                  <Route
                    path="/settings"
                    element={<Settings akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/fire"
                    element={<Fire akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/harvard/73!h$"
                    element={<Harvard setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/yale/152!oo$"
                    element={<Yale setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/stanford/a843a"
                    element={<Stanford setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/northwestern/952$!ay"
                    element={<Northwestern setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/georgiatech/14ga"
                    element={<GeorgiaTech setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/duke/62!y$"
                    element={<Duke setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/columbia/67!z$"
                    element={<Columbia setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/cornell/322!a$"
                    element={<Cornell setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/urbana/155e!"
                    element={<Urbana setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/caltech/234!c"
                    element={<CalTech setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/upenn/39!a!"
                    element={<UPenn universityName={'UPenn'} setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/princeton/33!Oa$"
                    element={<Princeton setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/vanderbilt/23!O$"
                    element={<Vanderbilt setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/umich/7!h2$"
                    element={<Umich setCookie={setCookie} loggedIn={loggedIn} setDisableNavbar={setDisableNavbar} akrAuth={akrAuth} akrCookie={akrCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/search/:query?"
                    element={<Search />}
                  />
                  <Route
                    path="/course/:courseId?"
                    element={<Course akrAuth={akrAuth} akrCookie={akrCookie} loggedIn={loggedIn} setLoggedIn={setLoggedIn} setCookie={setCookie} />}
                  />
                  <Route
                    path="/review/essay/:query?"
                    element={<EssayReviewer />}
                  />
                  <Route
                    path="/DinoHarvardGame"
                    element={<DinoHarvardGame />}
                  />
                  <Route
                    path="/roblox/shop"
                    element={<RobloxShop />}
                  />
                  <Route
                    path="/roblox/shop/:assetId"
                    element={<RobloxShop />}
                  />
                  <Route
                    path="/roblox/ugc"
                    element={<RobloxUGC />}
                  />
                  <Route
                    path="/chat"
                    element={loggedIn ? <ChatDashboard setLoggedIn={setLoggedIn} akrAuth={akrAuth} akrCookie={akrCookie} /> : <Login setCookie={setCookie} setLoggedIn={setLoggedIn} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/voice"
                    element={<Voice setCookie={setCookie} akrAuth={akrAuth} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/hi"
                    element={<HiBye type='0' setCookie={setCookie} akrAuth={akrAuth} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/demo/tutors"
                    element={<DemoTutors loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} setCookie={setCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/bye"
                    element={<HiBye type='1' setCookie={setCookie} akrAuth={akrAuth} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/friends/:userId"
                    element={<FFFollowing numberType='0' />}
                  />
                  <Route
                    path="/followers/:userId"
                    element={<FFFollowing numberType='1' />}
                  />
                  <Route
                    path="/following/:userId"
                    element={<FFFollowing numberType='2' />}
                  />
                  <Route
                    path="/update207/:cookie"
                    element={<UpdateAcc setCookie={setCookie} akrAuth={akrAuth} loggedIn={loggedIn} />}
                  />
                  <Route
                    path="/billyvspotatohead"
                    element={<BillyVsPotatoHead />}
                  />
                  <Route
                    path="/games"
                    element={<Games />}
                  />
                  <Route
                    path="/snake"
                    element={<Snake />}
                  />
                  <Route
                    path="/redeem"
                    element={<Redeem />}
                  />
                  <Route
                    path="/math"
                    element={<MathSolver />}
                  />
                  <Route
                    path="/demontutors"
                    element={<DemonTutors loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} setCookie={setCookie} logOutUser={logOutUser} />}
                  />
                  <Route
                    path="/dt"
                    element={<Navigate to='/demontutors' replace />}
                  />
                  <Route
                    path="/me"
                    element={<Navigate to='/profile/2' replace />}
                  />
                  <Route
                    path="/articles"
                    element={<Articles />}
                  />
                  <Route
                    path="/roblox/games"
                    element={<RobloxGames />}
                  />
                  <Route
                    path="/home"
                    element={<Home loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/roblox"
                    element={<RobloxHome loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/malakeye"
                    element={<Malakeye loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/roblox/games/:gameId"
                    element={<RobloxGamesSpecific loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/roblox/user/:userId"
                    element={<RobloxUserSpecific loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/roblox/catalog/:assetId"
                    element={<RobloxLimitedSpecific loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/roblox/catalog/"
                    element={<SearchRobloxLimiteds loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/roblox/users/"
                    element={<SearchRobloxUsers loggedIn={loggedIn} akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/article/:articleId"
                    element={<Article akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="edit/article/:articleId"
                    element={<EditArticle akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="/media/:mediaId"
                    element={<Media akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route
                    path="secure"
                    element={<Secure akrAuth={akrAuth} akrCookie={akrCookie} />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </Router>
          </NotificationProvider>
        </ImageCacheProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;